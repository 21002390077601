import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import lottie from 'lottie-web'

export default class extends Controller {
  connect() {

    var params = {
      container: this.element,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: this.element.dataset.path
    }

    this.load_animation(this.element.dataset.animation, params)
  }

  load_animation(name, params) {
    var animation = lottie.loadAnimation(params)
    if(name == "splash"){
      this.respond_splash_animation(animation)
    }
  }

  respond_splash_animation(animation) {
    animation.addEventListener('config_ready', function(){
      var svg = document.querySelector('#animation svg')
      var animationContainer = document.getElementById("animation")

      const viewBoxWidth = 1440
      const viewBoxHeight = 1070

      const containerHeight = animationContainer.offsetHeight
      const containerWidth = animationContainer.offsetWidth

      let ratio = 1.0
      if((viewBoxHeight/containerHeight) < (viewBoxWidth/containerWidth)) {
        ratio = containerHeight / viewBoxHeight
      }else{
        ratio = containerWidth / viewBoxWidth
      }
      const width = (viewBoxWidth * ratio)
      const height = (viewBoxHeight * ratio)
      svg.style.width = (viewBoxWidth * ratio) + 'px'
      svg.style.height = (viewBoxHeight * ratio) + 'px'
      svg.style.marginLeft = "-" + ((width - containerWidth)/2) + "px"
      svg.style.marginTop = "-" + ((height - containerHeight)/2) + "px"

    });
  }
}

